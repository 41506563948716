import * as React from "react"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'gatsby'

const NotFound = () => {
    return (
        <div>
            <body className="bg-gray-800">
            <Header />
        <div className="flex flex-col min-h-screen bg-gray-900">
  <div className="m-auto text-white text-center">
    <h1 className="text-6xl text-indigo-400">404</h1>
    <p className="text-2xl">The requested path wasn't found.</p>
    <Link to="/">
                  <button className="mt-3 whitespace-nowrap inline-flex items-center justify-center px-3 py-1 border border-indigo-400 rounded-md shadow-sm text-sm font-medium text-indigo-400 bg-transparent hover: border-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200 ease-in-out">Take me home</button>
                  </Link>
  </div>
</div>
<Footer />
</body>
</div>
    );
}
export default NotFound;
